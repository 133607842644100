<template>
    <v-dialog v-model="dialog" persistent max-width="1500px">

        <v-card :loading="loading">
            <v-card-text>
                <v-form ref="form"
                        v-model="valid"
                        class="mt-4 ml-4 mr-4"
                        lazy-validation>
                    <v-tabs>
                        <v-tab>Anagrafica</v-tab>
                        <v-tab v-if="isPro">IBAN</v-tab>
                        <v-tab-item>
                            <v-row class="mt-4">
                                <v-col cols="3">
                                    <v-text-field v-model="utente.username"
                                                  label="Username"
                                                  required
                                                  autocomplete="new-user"
                                                  outlined
                                                  dense
                                                  :disabled="!nuovo"
                                                  :rules="[v => !!v || 'campo obbligatorio', v => /.+@.+\..+/.test(v) || 'inserire una email valida' ]">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="utente.password"
                                                  label="Password"
                                                  autocomplete="new-password"
                                                  required
                                                  outlined
                                                  :disabled="!nuovo"
                                                  type='password'
                                                  dense></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="utente.isGestoreStudio"
                                                :disabled="isServicePro"
                                                :label="isStudioPro ? 'Gestore Studio' : 'Gestore azienda (obbligatorio e unico)'">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="utente.isReception"
                                                :label="isStudioPro ? 'Abilitato Reception' : 'Abilitato Amministrazione'">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="utente.operatore.isAccessoLimitato"
                                                label="Accesso Limitato">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <v-text-field v-model="utente.operatore.nome"
                                                  label="Nome"
                                                  :rules="[v=> ((!!v && v.length>0) || utente.isReception) || 'inserire il nome']"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="utente.operatore.cognome"
                                                  label="Cognome"
                                                  :rules="[v=> (!!v && v.length>0 ) || 'inserire il cognome']"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3">
                                    <v-text-field v-model="utente.operatore.mail"
                                                  label="Mail"
                                                  outlined
                                                  dense
                                                  :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="utente.operatore.telefono"
                                                  label="Telefono"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col v-if="isStudioPro" cols="6">
                                    <v-checkbox class="mt-0"
                                                v-model="isPro"
                                                :disabled="isProLocked"
                                                :label="isProLocked ? 'Professionista (impostazione non modificabile, sono presenti clienti associati)':'Professionista'">
                                    </v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row v-if="isPro && isStudioPro">
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.denominazione"
                                                  label="Denominazione"
                                                  outlined
                                                  dense
                                                  required
                                                  :rules="[v=> v!=null || v.length>0 || 'inserire la denominazione']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.partitaIVA"
                                                  label="Partita IVA"
                                                  outlined
                                                  dense
                                                  required
                                                  :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || 'inserire 11 cifre']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.codiceFiscale"
                                                  label="Codice Fiscale"
                                                  outlined
                                                  dense
                                                  required
                                                  @keyup="uppercaseCFLR"
                                                  :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v) || 'inserire 11 cifre o 16 caratteri']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.iban"
                                                  label="IBAN Professionista"
                                                  outlined
                                                  dense
                                                  required
                                                  :rules="[v=> (v==null || v.length==0 || /^(IT)[0-9]{2}[A-Z]{1}[0-9A-Z]{10}[0-9A-Z]{12}$/.test(v)) || 'inserire un IBAN valido']">
                                    </v-text-field>
                                </v-col>

                            </v-row>

                            <v-row v-if="isPro && isStudioPro">
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.mail"
                                                  label="Mail Professionista"
                                                  outlined
                                                  dense
                                                  required
                                                  :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.telefono"
                                                  label="Telefono Professionista"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.pec"
                                                  label="PEC"
                                                  outlined
                                                  dense
                                                  required
                                                  :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="professionista.sdi"
                                                  label="SDI"
                                                  outlined
                                                  @keyup="uppercaseSDI"
                                                  :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{7}$/.test(v) ) || 'inserire 7 caratteri']"
                                                  dense>
                                    </v-text-field>

                                </v-col>
                            </v-row>
                            <v-row v-if="isPro && isStudioPro">
                                <v-col cols="6">
                                    <v-text-field v-model="professionista.stripe_pk"
                                                  label="Secret Key Stripe"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.sumUp_uid"
                                                  label="UID SumUp"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="professionista.sumUp_key"
                                                  label="Key SumUp"
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </v-tab-item>
                        <v-tab-item v-if="isPro">
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table :headers="headers"
                                                  :items="listaConti"
                                                  item-key="conto.id"
                                                  ref="tabella"
                                                  :loading="loading"
                                                  :items-per-page="5"
                                                  class="elevation-1 ma-2"
                                                  :footer-props="{
                                                    'items-per-page-text':'Pagina da',
                                                    'items-per-page-all-text': '',
                                                    'items-per-page-options':[5]
                                                    }"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun conto presente in archivio"
                                                  no-results-text="Nessuna conto corrispodende ai criteri di ricerca">
                                        <template slot="header.operazione">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" color="info" class="ml-2"
                                                           v-on="on"
                                                           icon @click="newConto">
                                                        <v-icon>mdi-folder-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Aggiungi Conto
                                                </span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.descrizione="{ item }">
                                            <v-text-field v-model="item.descrizione"
                                                          hide-details
                                                          outlined
                                                          dense
                                                          style="font-size:smaller"
                                                          :rules="[v=> !!v || '']">
                                            </v-text-field>
                                        </template>
                                        <template v-slot:item.bic="{ item }">
                                            <v-text-field v-model="item.bic"
                                                          hide-details
                                                          outlined
                                                          dense
                                                          style="font-size:smaller">
                                            </v-text-field>
                                        </template>
                                        <template v-slot:item.intestazione="{ item }">
                                            <v-text-field v-model="item.intestazione"
                                                          hide-details
                                                          outlined
                                                          dense
                                                          style="font-size:smaller"
                                                          :rules="[v=> !!v || '']">
                                            </v-text-field>
                                        </template>
                                        <template v-slot:item.iban="{ item }">
                                            <v-text-field v-model="item.iban"
                                                          hide-details
                                                          outlined
                                                          dense
                                                          style="font-size:smaller"
                                                          :rules="[v=> v==null || v.length==0 || v.length<=32 || 'massimo 32 caratteri']">
                                            </v-text-field>
                                        </template>
                                        <template v-slot:item.isDefault="{ item }">
                                            <v-checkbox v-model="item.isDefault">
                                            </v-checkbox>
                                        </template>
                                        <template v-slot:item.operazione="{ item }">
                                            <v-tooltip bottom v-if="isPro || ($store.state.utente && $store.state.utente.isGestoreStudio) || ($store.state.utente && $store.state.utente.isReception)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           icon @click="eliminaConto(item)"
                                                           color="red darken-3" class="ml-2">
                                                        <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Elimina Conto
                                                </span>
                                            </v-tooltip>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-spacer></v-spacer>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="valid"
                               @click="salva"
                               :loading="loadingSalva"
                               :disabled="!valid">
                            Salva
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="orange darken-2" dark
                               :loading="loadingAnnulla"
                               @click="annulla">
                            Annulla modifiche
                        </v-btn>
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="dialog = false">
                            Chiudi
                        </v-btn>
                    </v-tabs>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
        <v-snackbar v-model="snackErrorOp"
                    timeout="2000"
                    color="error"
                    centered>
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore: superato limite operatori
        </v-snackbar>
        <v-snackbar v-model="snackErrorProf"
                    timeout="2000"
                    color="error"
                    centered>
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore: superato limite professionisti
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import { callService, callPost, Snack } from '@/modules/utilities.js'
    import { bus } from '@/main.js'


    export default {
        data: () => ({
            dialog: false,
            valid: true,
            nuovo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            snackErrorOp: false,
            snackErrorProf: false,
            dialogDelete: false,
            loading: false,
            loadingSalva: false,
            loadingAnnulla: false,
            isPro: false,
            isProLocked: false,
            id: '',
            professionista:{                
                mail: '',
                denominazione: '',
                codiceFiscale: '',
                partitaIVA: '',
                pec:'',
                telefono: '',
                sdi:''
            },
            utente: {
                id: '',
                username: '',
                denominazione: '',
                codiceFiscale: '',
                partitaIVA: '',
                pec: '',
                scadenzaPEC: null,
                sdi: '',
                operatore:''
            },
            headers: [
                { text: 'Descrizione', value: 'descrizione', width: '20%' },
                { text: 'Intestazione', value: 'intestazione', width: '20%' },
                { text: 'IBAN', value: 'iban', width: '25%' },
                { text: 'Causale', value: 'bic', width: '25%' },
                { text: 'Default', value: 'isDefault' },
                { text: '', value: 'operazione', sortable: false }, 
            ],
            listaConti: [],
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" });
            }            
        },
        computed: {
            isLavoro() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isLavoro;
            },
            isContabilita() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isContabilita;
            },
            isPulizia() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
            },
            isManutenzione() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
            },
            isChiamata() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
            },
            isServicePro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isPulizia || this.$store.state.lplApp.isChiamata || this.$store.state.lplApp.isManutenzione);
            },
            isStudioPro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isLavoro || this.$store.state.lplApp.isContabilita);
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" });
            },
            uppercaseCFLR() {
                this.professionista.codiceFiscale = this.professionista.codiceFiscale.toUpperCase();
            },
            uppercaseSDI() {
                this.professionista.sdi = this.professionista.sdi.toUpperCase();
            },
            checkData(d) {
                var tok = d.split("/");
                var dIso = new Date([tok[2],tok[1],tok[0]].join("-"));
                return !isNaN(dIso);
            },
            localData(stringDate) {
                if (!stringDate) return null;
                var d = new Date(stringDate);
                let month = (d.getMonth() + 1).toString();
                let day = d.getDate().toString();
                let year = d.getFullYear();
                if (month.length < 2) {
                    month = '0' + month;
                }
                if (day.length < 2) {
                    day = '0' + day;
                }
                return [day, month, year].join('/');                
            },
            serverData(stringDate) {
                //da gg/mm/aaaa a aaaa-mm-gg//

                if (!stringDate) return null;
                var tok = stringDate.split("/");                
                return [tok[2], tok[1], tok[0]].join('/');                
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);    
                this.loadingAnnulla = false;
            },            
            async onUtenteChanged({ lista, sessionID }) {
                if (sessionID == "-1" || sessionID == this.$store.state.sessionID) return;

                if (lista.find(cod => cod == this.utente.id)) {
                    await this.internalCreated(Snack.remote);
                }
            }, 
            async salva() {
                if (!this.$refs.form.validate()) return;

                //check utenti-licenza
                if (this.utente.id == "0") {
                    let nProf = this.$store.state.listaOperatoriProfessionisti.length;
                    let nOp = this.$store.state.listaOperatore.length - nProf;

                    if (!this.isPro && nOp >= this.$store.state.lplApp.nOperatori) {
                        this.snackErrorOp = true;
                        return;
                    }
                    else if (this.isPro && nProf >= this.$store.state.lplApp.nProfessionisti) {
                        this.snackErrorProf = true;
                        return;
                    }
                }

                let paramJson={
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    utente: this.utente,
                    professionista: this.professionista,
                    isPro: this.isPro,
                    isStudioPro: this.isStudioPro,
                    conti: this.listaConti,
                };

                this.loadingSalva = true;
                let ute = await callPost('api/utente/save', paramJson);

                if (ute) {
                    this.utente.id = ute.id;
                    this.nuovo = false;
                    bus.$emit('utente-changed-bus', { lista: [this.utente.id], sessionID: "-1" });
                    bus.$emit('utente-snack-succes',{});
                    this.loadingSalva = false;  
                    this.dialog = false;
                }
                else {
                    await this.internalCreated(Snack.error);
                    this.loadingSalva = false;   
                }

            },
            async internalCreated(snack) {
                let param=[];
           
                param=[this.utente.id];
                let utenteEsteso = await callService("api/utente", param);
                let utente = utenteEsteso.utente;
                if (!this.nuovo) {
                    if (utente.operatore.professionista) {
                        this.professionista = utente.operatore.professionista;
                        this.isPro = utente.operatore.isProfessionista;
                        this.isProLocked = utenteEsteso.isProLocked;
                        let param2=[];
                        param2=[utente.operatore.professionista.id];
                        this.listaConti = await callService("api/conto/conti", param2);
                    }
                    else {
                        this.isPro = false;
                        this.isProLocked = false;
                        this.listaConti = [];
                    }
                }
                else {
                    this.isPro = false;
                    this.isProLocked = false;
                    this.listaConti = [];
                    this.$nextTick(() => {
                        this.$refs.form.resetValidation();
                    });
                }

                this.utente = utente;
    
                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            newConto() {

                let nConto =
                {
                    descrizione: "nuovo conto"
                }

                this.listaConti.push(
                    {
                        //conto: nConto
                        descrizione: "nuovo conto"
                    })
            },
            eliminaConto(item) {
                var i = this.listaConti.indexOf(item);
                this.listaConti.splice(i, 1);
            },             
        },
        async created() {           

            this.$updaterHub.$on('utente-changed', this.onUtenteChanged)
            var vm = this;

            bus.$on('dialog-show-utente', async function (utente) {
                vm.dialog = true;
                vm.loading = true;
                vm.nuovo = utente.id == "0";
                vm.utente.id = utente.id;
                await vm.internalCreated(Snack.nothing);
                vm.loading = false;
                vm.utente.password = new Date().getFullYear();
            });   
        },
        beforeDestroy() {
            this.$updaterHub.$off('utente-changed', this.onUtenteChanged);
            this.$updaterHub.$off('dialog-show-utente');
        }
    }

</script>

