<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row class="mt-n8">
                                    <v-col cols="3">
                                        <v-select v-model="idOperatoreScadenza"
                                                  :items="listaOperatore"
                                                  item-value="id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Filtra per Operatore"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>


                                    <v-col cols="3">
                                        <v-select v-model="professionista.id"
                                                  :items="listaOperatoriProfessionisti"
                                                  item-value="professionista.id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Filtra per Professionista"
                                                  :disabled="isAccessoLimitato"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field class="mx-1"
                                                      v-model="dataDa"
                                                      label="Scadenza dal giorno"
                                                      outlined
                                                      clearable
                                                      persistent-hint
                                                      type="date"
                                                      @change="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="dataA"
                                                      label="Scadenza al giorno"
                                                      outlined
                                                      clearable
                                                      persistent-hint
                                                      type="date"
                                                      @change="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-data-table v-model="selezione"
                                          :headers="headers"
                                          :items="scadenziario"
                                          ref="tabella"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          progress="78"
                                          class="elevation-1 mt-n4"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"                                          
                                          @pagination="pagination"
                                           :search="search"
                                          :custom-filter="customFilter"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessuna scadenza presente in archivio"
                                          no-results-text="Nessuna scadenza corrispodende ai criteri di ricerca">
                                <template v-slot:item.menu="{item}">
                                    <v-btn icon>
                                        <v-icon color="orange darken-2" @click="visualizza(item)">
                                            mdi-launch
                                        </v-icon>
                                    </v-btn>    
                                </template>                              

                                <template v-slot:item.dataScadenza="{ item }">                                    
                                    <span class="text-caption grey--text" v-text="localData(item.dataScadenza)" />                                    
                                </template>

                                <template v-slot:item.cliente.denominazioneNormalizzata="{ item }">
                                    <span class="text-subtitle font-weight-medium">[</span>
                                    <span class="text-subtitle font-weight-medium" v-text="item.cliente.codice" />
                                    <span class="text-subtitle font-weight-medium">]&nbsp;</span>
                                    <span class="text-subtitle font-weight-medium" v-text="item.cliente.denominazioneNormalizzata" />
                                </template>      
                            </v-data-table>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>


    </v-container>   

</template>


<script>
    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, Snack, gDataCalendar } from '@/modules/utilities.js'
    import ScadenzaDialog from '@/views/components/ScadenzaDialog.vue'
    import { bus } from '@/main.js'

    export default {
                components: {
                    ScadenzaDialog
                }, 
                data: () => ({
                    selezione: [],
                    filtro: [],
                    statoAll: false,
                    loading: true,
                    showError: false,
                    viewMenuUtente: false,
                    snackSuccess: false,
                    snackError: false,
                    snackCancel: false,
                    snackRemote: false,
                    x: 0,
                    y: 0,
                    nPag: 8,
                    isAccessoLimitato: false,
                    mb: 1,
                    dialogMB: false,
                    validMB: false,
                    loadingMB: false,
                    esitoMBOK: false,
                    esitoMBKO: false,
                    cs: 1,
                    dialogCS: false,
                    validCS: false,
                    loadingCS: false,
                    esitoCSOK: false,
                    esitoCSKO: false,
                    ao: 1,
                    dialogAO: false,
                    validAO: false,
                    loadingAO: false,
                    esitoAOOK: false,
                    esitoAOKO: false,
                    idAssegnaOperatore: '',
                    search: '',
                    searchText: '',
                    dataA: '',
                    dataDa: '',
                    scadenziario: [],                   
                    headers: [
                        { text: 'Azioni', value: 'menu' },
                        { text: 'Tipo', value: 'tipo' },
                        { text: 'Cliente', value: 'cliente.denominazioneNormalizzata', width: '18%' },
                        { text: 'Responsabile', value: 'operatore.denominazioneNormalizzata', width: '15%' },
                        { text: 'Professionista', value: 'professionista.denominazione', width: '15%' },
                        { text: 'Data Scadenza', align: 'center', value: 'dataScadenza',sortable:true,
                            sort: function(a, b) {
                                if(a<b) return -1;
                                if(a>b) return 1;
                                return 0;
                            },
                        },
                        { text: 'Descrizione', value: 'descrizione' },                       
                    ],
                    conteggioUtenti: [],
                    idOperatoreScadenza: {},
                    notifica: true,
                    operatore: { id: '' },
                    professionista: { id: '' },
                    cliente: { id: '' },
                    notifica1: true,
                    notifica2: true,
                    notifica3: false,
                    moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
                    },
                }),
                methods: {
                    visualizza(item) {
                        if(item.tipo=="PEC") {
                            let cliente = JSON.parse(JSON.stringify(item.cliente));
                            bus.$emit('dialog-show-cliente', cliente);
                        }
                        if(item.tipo=="Pratica") {
                            let pratica = JSON.parse(JSON.stringify(item.pratica));
                            bus.$emit('dialog-show-pratica', pratica);
                        }                       
                    },
                    localData(v) {
                        return gLocalData(v);
                    },
                    importoEuro(v) {
                        return euro(v);
                    },
                    dataCalendar(v) {
                        return gDataCalendar(v);
                    },                               
                    filterItems(v){
                        this.$nextTick(() => {
                            this.filtro=this.$refs.tabella.$children[0].filteredItems;
                        });
                    },                                                    
                    pagination(pag) {
                        let nPag = pag.itemsPerPage;
                        this.$cookie.set('scadenziario-pagination', nPag.toString(), { expires: '1Y' });
                    },
                    async internalCreated(snack) {
                        let param = [];
                        this.scadenziario = await callService('api/scadenziario', param);
                        this.changeSearch();

                        this.snackSuccess = (snack == Snack.success);
                        this.snackError = (snack == Snack.error);
                        this.snackCancel = (snack == Snack.cancel);
                        this.snackRemote = (snack == Snack.remote);
                    },       
                    customFilter(value, search, item) {
                        let ok = true;
                        if (ok && this.searchText) { //codice/dnz/fiscale in OR
                            let src = this.searchText.toLowerCase();
                            let ok1 = item.cliente.denominazioneNormalizzata && item.cliente.denominazioneNormalizzata.toLowerCase().indexOf(src) >= 0;
                            let ok2 = !ok1 && item.descrizione && item.descrizione.toLowerCase().indexOf(src) >= 0;
                            let ok3 = !ok2 && item.cliente.codiceFiscale && item.cliente.codiceFiscale.toLowerCase().indexOf(src) >= 0;
                            let ok4 = !ok3 && item.cliente.codiceFiscaleLR && item.cliente.codiceFiscaleLR.toLowerCase().indexOf(src) >= 0;
                            let ok5 = !ok4 && item.importo.toString().indexOf(src) >= 0;
                            ok = ok1 || ok2 || ok3 || ok4 || ok5;
                        }
                        if (ok && this.idOperatoreScadenza) {
                            ok = item.operatore && item.operatore.id == this.idOperatoreScadenza;
                        }
                        if (ok && this.professionista.id) {
                            ok = item.cliente.professionista.id == this.professionista.id;
                        }
                        if (ok && this.cliente.id) {
                            ok = item.cliente.id == this.cliente.id;
                        }
                        if (ok) {
                            if (this.statoScadenza == '3') //TUTTI
                                ok = ok;
                            else if (this.statoScadenza == '1') //NUOVE
                                ok = !item.dataInvioRichiesta
                            else if (this.statoScadenza == '2') //IN LAVORAZIONE
                                ok = item.dataInvioRichiesta && !item.isChiusa
                            else if (this.statoScadenza == '4') //CHIUSE
                                ok = item.dataInvioRichiesta && item.dataRisposta && item.isChiusa
                        }

                        if (ok && this.dataDa) {
                            ok = this.dataCalendar(item.dataScadenza) >= this.dataCalendar(this.dataDa);
                        }
                        if (ok && this.dataA) {
                            ok = this.dataCalendar(item.dataScadenza) <= this.dataCalendar(this.dataA);
                        }

                        return ok;
                    },
                    changeSearch() {
                        this.search =
                            this.searchText +
                            this.idOperatoreScadenza +
                            this.professionista.id +
                            this.cliente.id +
                            this.statoScadenza +
                            this.dataDa +
                            this.dataA
                    },
                    
                },                
                async created() {

                    this.clientiEstesi = this.$store.state.clientiEstesi;
                    this.listaOperatore = this.$store.state.listaOperatore;
                    this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;
                    this.nPag = Number(this.$cookie.get('scadenziario-pagination')); //leggo il coockie della paginazione

                    this.idOperatoreScadenza = this.$store.state.utente.operatore.id;

                    await this.internalCreated(Snack.nothing);


                    if (this.$store.state.utente.operatore.isProfessionista) {
                        this.professionista.id = this.$store.state.utente.operatore.professionista.id;
                        if (this.$store.state.utente.operatore.isAccessoLimitato) {
                            this.isAccessoLimitato = true;
                        }
                    }

                    this.loading = false;
                }               
    }
</script>
